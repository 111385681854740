.calendario {
    max-width: 525px;
	height: auto;
	border: 1px solid #e5e5e5;
    margin: 20px 0;
	.mes {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #706E6D;
        border-bottom: 1px solid #fff;
        text-align: center;
        height: 50px;
        span {
            color: #FFF;
            font-size: clamp(18px, 2vw, 24px);
        }
        a {
            display: block;
            width: 27px !important;
            height: 22px;
            float: left;
            margin-bottom: 0 !important;
            color: #FFF;
            line-height: 22px;
            font-weight: bold;
            text-align: center;
        }
    }
    table {
        width: 100%;
        clear: both;
        tr {
            th {
                width: 74px;
                height: 35px;
                border-right: 1px solid #fff;
                border-bottom: 1px solid #fff;
                background: #706E6D;
                color: #FFF;
                text-align: center;
                font-weight: normal;
                &:last-child {
                    border-right: none;
	                width: 70px
                }
            }
        }
        tr {
            td {
                width: 74px;
                height: 35px;
                background: #ffffff;
                border-right: 1px solid #d5d5d5;
                border-bottom: 1px solid #d5d5d5;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;
                &:hover {
                    background: #CCCCCC;
                }
                &:last-child {
                    border-right: none;
	                width: 70px
                }
                .select {
                    background: #d5d5d5;
                }
                .active {
                    background: #f58220;
                    color: #FFFFFF;
                }
                .disable {
                    background: #f1f1f3;
                    cursor: default;
                }
            }
        }
    }
}

