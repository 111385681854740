.aside__tutoriais {
  margin-top: 30px;
  .categorias {
    ul {
      list-style: none;
      padding: 0;
      li {
        a {
          display: block;
          font-size: 22px;
          padding: 4px 4px;
          border-radius: 25px;
          width: fit-content;
          &:hover {
            color: #fff;
            text-decoration: none;
            background-color: #00a0de;
            padding: 4px 8px;
          }
        }
        .active {
          color: #fff;
          text-decoration: none;
          background-color: #00a0de;
          padding: 4px 8px;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .aside__tutoriais {
    .categorias {
      ul {
        li {
          a {
            font-size: 21px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .aside__tutoriais {
    .categorias {
      ul {
        li {
          a {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .aside__tutoriais {
    margin-top: 20px;
    .categorias {
      ul {
        li {
          a {
            font-size: 18px;
          }
        }
      }
    }
  }
}
