.destaque {
  position: relative;
  padding: 60px 0;
  margin-bottom: 30px;
  border-radius: 30px;
  
  &__title {
    margin-bottom: 40px;
    color: white;
    font-size: 40px;
    font-weight: bold;
  }
  &__owl {
    .owl-stage-outer {
      border-radius: 30px;
      box-shadow: 15px 15px 10px rgba(black, .16);
    }
    .item {
      padding: 70px 40px;
      font-size: 25px;
      background-color: #EFEFEF;

      &-title {
        margin-bottom: 1rem;
        color: $orange;
        font-size: 35px;
        font-weight: bold;
        text-transform: uppercase;
      }
      &-subtitle {
        margin-bottom: 1rem;
        color: $orange;
        font-size: inherit;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
    .owl-nav {
      button.owl-prev,
      button.owl-next {
        position: absolute;
        top: 50%;
        color: white;
        font-size: 80px;
        text-shadow: 0 3px 6px rgba(black, .3);
        transition: all .3s;
      }
      button.owl-prev {
        left: 0;
        transform: translate(-90%, -50%);
        
        &:hover {
          padding-right: 20px !important;
        }
      }
      button.owl-next {
        right: 0;
        transform: translate(90%, -50%);
        
        &:hover {
          padding-left: 20px !important;
        }
      }
    }
  }
  &__box {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 30px;
    font-size: 25px;
    line-height: .7;
    background-color: white;
    border-radius: 20px;
    box-shadow: 15px 15px 10px rgba(black, .16);
    .row {
      align-items: center;
    }
    .box {
      &-title {
        margin-bottom: 8px;
        font-size: 25px;
        font-weight: bold;
      }
      &-subtitle {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
    .form-control {
      height: 52px;
      padding: 10px 25px;
      color: $gray;
      font-size: inherit;
      border: 1px solid $dark-gray;
      border-radius: 999px;

      &::placeholder {
        color: $gray;
      }
      option {
        font-size: 20px;
      }
    }
    .btn {
      height: 100%;
      font-size: inherit;
      font-size: 18px;

    }
  }
  &__veja-mais {
    display: block;
    margin-top: 20px;
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;

    &:hover {
      color: white;
    }
  }

  &--yellow {
    padding-bottom: 30px;
    margin-bottom: 80px;
    background: $gradient-yellow;
    background-size: 100% calc(100% - 80px);

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 80px;
      background-color: white;
    }
   
    .destaque__owl {
      margin-bottom: 50px;
    }
  }
  &--purple {
    background: $gradient-purple;
  }
  &--green {
    background: $gradient-green;
  }
  &--imprensa {
    background: $gradient-gray;
    .destaque__title{
      color: $dark-gray;
    }
    .destaque__veja-mais{
      background: $gradient-green;
      font-size: 14px;
      padding: 10px;
      border-radius: 9999px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin: 0 -10px;
      list-style: none;

      .item {
        flex: 0 0 33.3333%;
        padding: 10px 10px;

        &-wrap {
          display: flex;
          flex-direction: column;
          height: 100%;
          background-color: white;
          border-radius: 50px;
          overflow: hidden;

          &:hover {
            text-decoration: none;

            .item-link {
              text-decoration: underline;
            }
          }
        }
        &-img {
          height: 230px;
          overflow: hidden;

          img {
            width: 100%;
            min-height: 100%;
            height: auto;
          }
        }
        &-info {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          padding: 24px 30px;
        }
        &-date {
          display: block;
          color: $dark-gray;
          font-size: 20px;
          font-weight: bold;
          line-height: 1;
        }
        &-title {
          margin-bottom: 5px;
          font-size: 20px;
          line-height: 1;
        }
        &-link {
          display: block;
          align-self: flex-end;
          margin-top: auto;
          color: $green;
          font-size: 20px;
          font-weight: bold;
          line-height: 1;
          text-transform: uppercase;
        }
      }
    }
  }
  &--eventos {
    background: $blue;
    
    .destaque {
      &__veja-mais {
        font-size: 14px;
        border: solid white 2px;
        padding: 10px;
        border-radius: 9999px;
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      padding-left: 0;
      margin: 0 -10px;
      list-style: none;

      .item {
        flex: 0 0 33.3333%;
        padding: 10px 10px;

        &-wrap {
          display: flex;
          flex-direction: column;
          height: 100%;
          padding: 24px 40px;
          background: white;
          border-radius: 25px;
          overflow: hidden;

          &:hover {
            text-decoration: none;

            .item-link {
              text-decoration: underline;
            }
          }
        }
        &-date {
          display: block;
          color: $dark-gray;
          font-size: 20px;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 10px;
        }
        &-title {
          margin-bottom: 5px;
          font-size: 18px;
          font-weight: bold;
          line-height: 1;
        }
        &-info {
          margin-bottom: 10px;
          font-size: 18px;
          line-height: 1;
          margin-top: 10px;
        }
        &-link {
          display: block;
          align-self: flex-end;
          margin-top: auto;
          color: $blue;
          font-size: 20px;
          font-weight: bold;
          line-height: 1;
          text-transform: uppercase;
        }
      }
    }
  }
}

@media (max-width: 1799px) {
  .destaque {
    &__owl {
      .item {
        font-size: 22px;

        &-title {
          font-size: 30px;
        }
      }
      .owl-nav {
        button.owl-prev,
        button.owl-next {
          font-size: 70px;
        }
      }
    }
    &__box {
      font-size: 22px;
    }
    &__veja-mais {
      font-size: 25px;
    }

    &--imprensa {
      &__list {
        .item {
          &-img {
            height: 200px;
          }
          &-info {
            padding: 24px 30px;
          }
          &-date {
            font-size: 18px;
          }
          &-title {
            font-size: 18px;
          }
          &-link {
            font-size: 18px;
          }
        }
      }
    }
    &--eventos {
      &__list {
        .item {
          &-date {
            font-size: 18px;
          }
          
          &-link {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 1499px) {
  .destaque {
    &__title {
      font-size: 35px;
    }
    &__owl {
      .item {
        font-size: 20px;

        &-title {
          font-size: 25px;
        }
      }
      .owl-nav {
        button.owl-prev,
        button.owl-next {
          font-size: 60px;
        }
        button.owl-prev {  
          &:hover {
            padding-right: 20px !important;
          }
        }
        button.owl-next {
          &:hover {
            padding-left: 20px !important;
          }
        }
      }
    }
    &__box {
      padding: 30px;
      font-size: 20px;

      .box-title {
        font-size: 22px;
      }
      .form-control {
        height: 46px;
        padding: 8px 20px;

        option {
          font-size: 18px;
        }
      }
    }

    &--imprensa {
      &__list {
        margin: 0 -5px;

        .item {
          padding: 10px 5px;

          &-img {
            height: 180px;
          }
          &-info {
            padding: 24px 30px;
          }
          &-date {
            font-size: 17px;
          }
          
          &-link {
            font-size: 17px;
          }
        }
      }
    }
    &--eventos {
      &__list {
        margin: 0 -5px;

        .item {
          padding: 10px 5px;

          &-date {
            font-size: 17px;
          }
          &-title,
          &-info {
            font-size: 16px;
          }
          &-link {
            font-size: 17px;
          }
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .destaque {
    &__title {
      font-size: 30px;
    }
    &__owl {
      .item {
        font-size: 16px;
      }
      .owl-nav {
        button.owl-prev,
        button.owl-next {
          font-size: 40px;
        }
        button.owl-prev {
          &:hover {
            padding-right: 10px !important;
          }
        }
        button.owl-next {
           &:hover {
            padding-left: 10px !important;
          }
        }
      }
    }
    &__box {
      padding: 25px;
      font-size: 16px;

      .box-title {
        font-size: 20px;
      }
      .form-control {
        height: 42px;
        padding: 8px 20px;

        option {
          font-size: 16px;
        }
      }
    }
    &__veja-mais {
      font-size: 20px;
    }

    &--imprensa {
      &__list {
        .item {
          &-wrap {
            border-radius: 40px;
          }
          &-img {
            height: 160px;
          }
          &-info {
            padding: 20px;
          }
          &-date {
            font-size: 15px;
          }
          &-link {
            font-size: 15px;
          }
        }
      }
    }
    &--eventos {
      &__list {
        .item {
          &-wrap {
            padding: 20px;
          }
          &-date {
            font-size: 15px;
          }
          
          &-link {
            font-size: 15px;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .destaque {
    &__owl {
      .owl-stage-outer {
        border-radius: 40px;
      }
      .item {
        padding: 50px 30px;

      }
      .owl-nav {
        button.owl-prev,
        button.owl-next {
          font-size: 40px;
        }
      }
    }
    &__box {
      height: auto;
      padding: 25px 15px;
      margin-top: 40px;
    }

    &--yellow {
      .destaque__owl {
        margin-bottom: 20px;
      }
    }
    &--imprensa {
      &__list {
        .item {
          &-wrap {
            border-radius: 30px;
          }
          &-img {
            height: 140px;
          }
          &-info {
            padding: 15px;
          }
          &-date {
            font-size: 14px;
          }
          &-title {
            font-size: 16px;
          }
          &-link {
            font-size: 14px;
          }
        }
      }
    }
    &--eventos {
      &__list {
        .item {
          &-wrap {
            padding: 15px;
          }
          &-date {
            font-size: 14px;
          }
          &-title,
          &-info {
            font-size: 16px;
          }
          &-link {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .destaque {
    &__title {
      margin-bottom: 20px;
    }
    &__owl {
      .owl-stage-outer {
        border-radius: 30px;
      }
      .item {
        padding: 30px 15px;

        &-title {
          font-size: 22px;
        }
      }
      .owl-dots {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        .owl-dot {
          width: 12px;
          height: 12px;
          margin: 3px;
          background-color: transparent;
          border: 2px solid white;
          border-radius: 100%;

          &.active {
            background-color: white;
          }
        }
      }
    }

    &--imprensa {
      &__list {
        flex-wrap: wrap;

        .item {
          flex: 0 0 100%;

          &-img {
            height: 180px;
          }
        }
      }
    }
    &--eventos {
      &__list {
        flex-wrap: wrap;

        .item {
          flex: 0 0 100%;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .destaque {
    padding: 40px 0;

    &__title {
      font-size: 25px;
    }
    &__owl {
      .owl-stage-outer {
        border-radius: 20px;
      }
      .owl-dots {
        margin-top: 20px;
      }
    }
    &__box {
      height: auto;
      padding: 15px 15px;
      margin-top: 40px;
      border-radius: 20px;
    }

    &--yellow {
      padding-bottom: 0;
      margin-bottom: 50px;
    }
    &--imprensa {
      &__list {
        .item {
          &-wrap {
            border-radius: 20px;
          }
          &-img {
            height: 140px;
          }
        }
      }
    }
    &--eventos {
      
      &__list {
        .item {
          &-wrap {
            border-radius: 20px;
          }
        }
      }
    }
  }
}