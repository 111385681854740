.imprensa {
    .detalhes {
        &__title {
            display: flex;
            align-items: center;
            font-size: 32px;
        }
        &__text {
            font-size: 20px;
            padding-left: 30px;
        }
    }
}

@media (max-width: 1440px){
    .imprensa {
        .detalhes {
            &__title {
                font-size: 28px;
            }
        }
    }
}
@media (max-width: 1024px){
    .imprensa {
        .detalhes {
            &__title {
                font-size: 24px;
            }
        }
    }
}
@media (max-width: 768px){
    .imprensa {
        .detalhes {
            &__title {
                font-size: 22px;
            }
            &__text {
                font-size: 16px;
            }
        }
    }
}
@media (max-width: 540px){
    .imprensa {
        .detalhes {
            &__title {
                font-size: 20px;
            }
        }
    }
}