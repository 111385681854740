.perguntas {
    margin-top: 30px;
    .back {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        &__title {
            display: flex;
            align-items: center;
            font-size: clamp(18px, 2vw, 32px);
            margin-bottom: 0;
            &::before {
                content: "";
                display: block;
                background: url('../img/img-menu-blue.png');
                background-repeat: no-repeat;
                background-size: contain;
                width: 30px;
                height: 30px;
            }
        }
        .btn {
            background-color: #D5D5D5;
            color: #605D5C;
            font-size: clamp(16px, 2vw, 20px);
            text-transform: none;
            font-weight: normal;
        }
    }
    .acordion {
        &__item {
            padding-bottom: 10px;
            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: clamp(18px, 2vw, 24px);
                border-bottom: 1px solid #D5D5D5;
                width: 100%;
                &:hover {
                    text-decoration: none;
                }
                &::after {
                    content: "";
                    display: block;
                    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 20px;
                    height: 20px;
                    transition: transform 300ms;
                }
            }
            .acordion__desc {
                display: flex;
                align-items: flex-start;
                margin-top: 10px;
                height: 0;
                overflow: hidden;
                transition: min-height 700ms;
                p {
                    font-size: clamp(16px, 2vw, 18px);
                }
            }
        }
        .active {
            a {
                color: #00A0DE;
                &::after {
                    content: "";
                    display: block;
                    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
                    background-repeat: no-repeat;
                    background-size: contain;
                    width: 20px;
                    height: 20px;
                    transform: rotate(180deg);
                }
            }
            .acordion__desc {
                height: max-content;
                overflow: visible;
                
            }
        }
    }
}


@media(max-width: 540px){
    .perguntas {
        margin-top: 0;
        .back {
            margin-bottom: 20px;
        }
    }
}