.aside__eventos {
  margin-top: 40px;
  &__categorias {
    .categorias {
      list-style: none;
      padding: 0;
      .item {
        margin-bottom: 10px;
        &__link {
          display: flex;
          gap: 8px;
          align-items: center;
          color: #5d9b16;
          font-size: 30px;
          padding: 10px 15px 10px 0;
          &::before {
            content: "";
            display: block;
            width: 5px;
            height: 38px;
            border-radius: 8px;
            background-color: #5d9b16;
          }
          &:hover {
            text-decoration: none;
            background-color: #5d9b163d;
            border-radius: 8px;
            display: block;
            padding: 10px 15px 10px 13px;
            &::before {
              display: none;
            }
          }
        }
        .active {
          text-decoration: none;
          background-color: #5d9b163d;
          border-radius: 8px;
          display: block;
          padding: 10px 15px 10px 13px;
          &::before {
            display: none;
          }
        }
      }
    }
  }
  .aside__imprensa__produtos {
    .produtos {
      list-style: none;
      padding: 0;
      .item {
        &__link {
          display: block;
          font-size: 22px;
          padding: 4px 0;
          &:hover {
            color: #5d9b16;
            text-decoration: none;
          }
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .aside__eventos {
    &__categorias {
      .categorias {
        .item {
          &__link {
            font-size: 21px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .aside__eventos {
    &__categorias {
      .categorias {
        .item {
          &__link {
            font-size: 20px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .aside__eventos {
    &__categorias {
      .categorias {
        .item {
          &__link {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .aside__eventos {
    margin-top: 10px;
    &__categorias {
      .categorias {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: 0;
      }
    }
  }
}