.aside__imprensa {
    &__categorias {
      .categorias {
        list-style: none;
        padding: 0;
        .item {
          margin-bottom: 10px;
          &__link {
            color: $white;
            font-size: 24px;
            background: $orange;
            padding: 10px 30px 10px 15px;
            border-radius: 5px;
          }
          .active {
              text-decoration: none;
              background-color: #5d9b163d;
              border-radius: 8px;
              display: block;
              padding: 10px 15px 10px 13px;
              &::before {
                display: none;
              }
          }
        }
      }
    }
    &__produtos {
      .produtos {
        list-style: none;
        padding: 0;
        .item {
          &__link {
            display: block;
            font-size: 18px;
            padding: 4px 0;
            &:hover {
              color: $orange;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  
  @media (max-width: 1440px) {
    .aside__imprensa {
        &__categorias {
          .categorias {
            .item {
              &__link {
                font-size: 21px;
              }
            }
          }
        }
        &__produtos {
          .produtos {
            .item {
              &__link {
                font-size: 16px;
              }
            }
          }
        }
      }
  }
  @media (max-width: 1024px) {
    .aside__imprensa {
      &__categorias {
        .categorias {
          .item {
            &__link {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .aside__imprensa {
      &__categorias {
        .categorias {
          .item {
            &__link {
              font-size: 18px;
            }
          }
        }
      }
      &__produtos {
        .produtos {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            border-radius: 5px;
            padding-left: 5px;
            margin-top: 0;
          .item {
            &__link {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  @media(max-width: 540px) {
    .aside__imprensa {
      margin-top: 10px;
        &__categorias {
          .categorias {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 0;
          }
        }
      }
  }