.produto__detalhe {
    .detalhe {
        margin-top: 30px;
        .back {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .detalhe__title {
                font-size: 32px;
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                &::before {
                    content: "";
                    display: block;
                    width: 30px;
                    height: 30px;
                    background: url('../img/img-menu-purple.png');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
            .btn__back {
                margin-bottom: 30px;
                background-color: #D5D5D5;
                color: #605D5C;
                font-size: clamp(16px, 2vw, 20px);
                text-transform: none;
                font-weight: normal;
                padding: 8px 24px;
                border-radius: 99999px;
                &:hover {
                    text-decoration: none;
                }
            }
        }
        &__text {
            font-size: 18px;
        }
    }
}

@media(max-width: 1440px){
    .produto__detalhe {
        .detalhe {
            .back {
                .detalhe__title {
                    font-size: 28px;
                }
            }
            &__text{
                font-size: 16px;
            }
        }
    }            
}
@media(max-width: 1024px){
    .produto__detalhe {
        .detalhe {
            .back {
                .detalhe__title {
                    font-size: 24px;
                }
            }
        }
    }            
}
@media(max-width: 768px){
    .produto__detalhe {
        .detalhe {
            .back {
                .detalhe__title {
                    font-size: 22px;
                }
            }

        }
    }            
}
@media(max-width: 540px){
    .produto__detalhe {
        .detalhe {
            .back {
                .detalhe__title {
                    font-size: 20px;
                }
            }
            &__text {
                font-size: 14px;
            }
        }
    }            
}