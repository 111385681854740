.representadas {
    &__empresas {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 20px;        
        a { 
            display: block;
            cursor: pointer;
            border: 2px solid #e8e8e8;
            border-radius: 5px;
            img {
                width: 100%;
            }
            &:hover {
                border: 2px solid #E35E12;
                border-radius: 5px;
            }
        }
        .owl-nav {
            display: flex;
            justify-content: center;
            gap: 40px;
            font-size: 38px;
        }
    }
}

@media (max-width: 1440px) {
    .representadas {
        &__empresas {
            grid-column-gap: 15px;
        }
    }
}
@media (max-width: 1200px){
    .representadas {
        &__empresas {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}


