.trabalhe-conosco{
  &__title{
    color: $white;
    background: $orange;
    font-size: 28px;
    padding: 15px 30px;
    border-radius: 5px;
  }
  &__desc{
    color: $dark-gray;
    background: #ffebd9;
    font-size: 16px;
    padding: 15px 30px;
    border-radius: 5px;
  }
  &__forms{
    margin-top: 30px;
    .item{
      border: solid 2px $light-gray;
      border-radius: 5px;
      padding: 30px;
      .title{
        font-weight: bold;
        line-height: .7;
        font-size: 24px;
      }
      .link{
        text-decoration: underline;
        margin-top: 15px;
        display: block;
      }
    }
  }
  .btn.vagas{
    gap: 15px;
  }
}
@media (max-width: 1499px){
  .trabalhe-conosco{
    &__title{
      font-size: 24px;
    }
  }
}
@media (max-width: 768px){
  .trabalhe-conosco{
    &__title{
      font-size: 20px;
      padding: 15px;
    }
    &__desc{
      padding: 15px;
    }
    &__forms .item{
      padding: 14px;
      border: none;
    }
    .btn{
      font-size: 14px;
    }
  }
}