.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 25px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  border-radius: 999px;

  &-gradient-yellow {
    color: white;
    background: $gradient-yellow;
    background-size: 105% 102%;
    background-position: center center;
    background-repeat: no-repeat;
    border: 0;

    &:hover {
      color: white;
    }
  }
}