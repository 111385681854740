.bula {
    .box-resultado{
        display: flex;
        align-items: center;
    }
    &__title {
        font-size: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &::before {
            content: "";
            display: block;
            width: 30px;
            height: 30px;
            background: url('../img/img-menu-purple.png');
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    &__list {
        list-style: none;
        padding: 0;
        .item {
            margin-bottom: 10px;
            &__link {
                font-size: 16px;
            }
        }
    }
    
}
@media(max-width: 1440px){
    .bula {
        &__title {
            font-size: 28px;
        }
    }
}
@media(max-width: 1199px){
    .box-resultado{
        flex-direction: column-reverse;
        align-items: start !important;
        gap: 30px;
    }
}
@media(max-width: 1024px){
    .bula {
        &__title {
            font-size: 24px;
        }
    }
}
@media(max-width: 768px){
    .bula {
        &__title {
            font-size: 22px;
        }
        &__list {
            .item {
                &__link {
                    font-size: 16px;
                }
            }
        }
    }
}
@media(max-width: 540px){
    .bula {
        &__title {
            font-size: 20px;
        }
    }
}