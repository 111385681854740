.historico {
    &__title {
        font-size: 32px;
        color: $orange;
        margin-top: 30px;
    }
    &__text {
        font-size: 18px;
        line-height: 1.1;
    }
    .box-carousel {
        display: flex;
        border: 1px solid #ebebeb;
        border-radius: 5px;
        margin-bottom: 20px;
        background-color: #fefefe;
        margin: 1rem 30px;
        button {
            width: 3%;
        }
        .carousel {
            width: 100%;
            &-inner {
                .carousel-item {
                    position: relative;
                    min-height: 212px;
                    .carousel-caption {
                        color: #707070;
                        text-align: left;
                        top: 0;
                        right: 3%;
                        left: 4%;
                        h5 {
                            color: $orange;
                            font-size: 24px;
                        }
                        p {
                            color: #707070;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        .historico__anos {
            .item {
                width: 100%;
                .ano__title {
                    font-size: clamp(20px, 3vw, 35px);
                    color: $orange;
                }
                .ano__text {
                    font-size: clamp(16px, 1vw, 20px);
                }
            }
        }
    }
}


@media (max-width: 1440px) {
    .historico {
        &__title {
            font-size: 28px;
        }
        &__text{
            font-size: 16px;
        }
        .box-carousel .carousel-inner .carousel-item .carousel-caption p{
            font-size: 16px;
        }
    }
}
@media (max-width: 1024px) {
    .historico {
        &__title {
            font-size: 24px;
        }
       .box-carousel {
        margin: 1rem 0;
        img {
            display: none;
        }
        .carousel-inner {
            .carousel-item {
                
                .carousel-caption {
                    font-size: 18px;
                }
            }
        }
      }
    }
}
@media (max-width: 768px) {
    .historico {
        &__title {
            font-size: 22px;
        }
        .historico__anos {
            padding: 25px 10px 55px 185px;
        }
        .box-carousel {
            .carousel-inner {
                .carousel-item {
                    min-height: 240px;
                }
            }
        }
    }
}
@media (max-width: 540px) {
    .historico {
        &__title {
            font-size: 20px;
        }
        &__text {
            font-size: 14px;
        }
        .historico__anos {
            padding: 0;
            padding-top: 175px;
            background-size: contain;
        }
        .box-carousel {
            .carousel-inner {
                .carousel-item {
                    min-height: 370px;
                    .carousel-caption {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
