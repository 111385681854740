.eventos {
  margin-top: 15px;
  .periodo {
    &--pleft {
      padding-left: 33px;
    }
    &__title {
      display: flex;
      align-items: center;
      font-size: 32px;
      &::before {
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        background: url("../img/img-menu-green.png");
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    label {
      font-size: 20px;
    }
    input {
      border-radius: 99999px;
      font-size: clamp(12px, 2vw, 14px);
      height: 42px;
      max-width: 150px;
    }
  }
  .produtos {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    &__text {
      font-size: 20px;
      margin-top: 5px;
      margin-bottom: 0;
    }
    &__checkbox {
      border: 1px solid #d5d5d5;
      border-radius: 8px;
      padding-block: 24px;
      .item {
        margin-bottom: 8px;
      }
    }
    .btn__filter {
      background-color: #5d9b16;
      color: #fff;
      font-size: clamp(16px, 2vw, 22px);
      align-self: flex-end;
      margin-top: 10px;
      padding: 8px 24px;
      border: 1px solid transparent;
      border-radius: 99999px;
    }
  }
  .resultado {
    &__title {
      display: flex;
      align-items: center;
      font-size: 24px;
      color: $white;
      margin-bottom: 10px;
      background: $orange;
      font-weight: normal;
      padding: 20px 15px;
      border-radius: 5px;
    }
    &__list {
        list-style: none;
        padding: 15px 0 15px 15px;
        background: #FFEBD9;
        border-radius: 5px;
        .item {
            &__link {
                display: flex;
                align-items: center;
                gap: 15px;
                margin-bottom: 20px;
                cursor: pointer;
                &:hover {
                    text-decoration: none;
                }
                .data {
                  background-color: $dark-gray;
                  font-size: 15px;
                  padding: 5px;
                  color: $white;
                  border-radius: 8px;
                }
                .desc {
                  &__title {
                    font-size: 18px;
                  }
                  &__text {
                    font-size: 16px;
                    margin-bottom: 0;
                  }
                }
            }
      }
      .cursor__default {
        cursor: default;
      }
    }
  }
}

@media (max-width: 1440px) {
  .eventos {
    .periodo {
      &__title {
        font-size: 28px;
      }
    }
    .resultado {
      &__title {
        font-size: 20px;
      }
      &__list {
        .item {
          &__link {
            .desc {
              &__title {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .eventos {
    .periodo {
      &__title {
        font-size: 24px;
      }
    }
    .resultado {
      &__title {
        font-size: 24px;
      }
      
    }
  }
}
@media(max-width: 768px) {
  .eventos {
      .periodo {
          &--pleft {
              padding-left: 0;
          }
          &__title {
              font-size: 22px;
          }
          label {
            font-size: 16px;
          }
      }
      .resultado {
        &__title {
          font-size: 18px;
        }
     }
  }
}
@media (max-width: 425px) {
  .eventos {
    .periodo {
      &__title {
          font-size: 20px;
      }
  }
    .resultado__list {
      .item {
        &__link {
          flex-direction: column;
          align-items: flex-start;
          &:first-child {
            margin-top: 20px;
          }
        }
      }
    }
    .resultado {
      &__title {
        font-size: 20px;
      }
    }
  }
}

