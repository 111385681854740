.busca {
    margin-top: 30px;
    &__text {
        font-size: 20px;
        margin-bottom: 30px;
    }
    .resultados__list {
        padding: 0;
        list-style: none;
        .item {
            margin-bottom: 30px;
            &__link {
                &:hover {
                    text-decoration: none;
                }
                &:hover > h4 {
                    color: #FFA304;
                }
                .item__title {
                    font-size: 20px;
                    font-weight: 600;
                }
                .arrow--orange {
                    display: flex;
                    &::before {
                        content: ">";
                        display: block;
                        width: 15px;
                        height: 20px;
                        color: #FFA304;
                    }
                }
                .link--blue {
                    color: #00A0DE;
                    text-decoration: underline;
                }
                .item__text {
                    border-top: 1px solid #eaeaea;
                    border-bottom: 1px solid #eaeaea;
                    margin-bottom: 0;
                    font-size: 18px;
                }
            }
        }       
    }
}

@media(max-width: 1440px){
    .busca {
        &__text {
            
        }
    }
}
@media(max-width: 1024px){
    .busca {
        &__text {
            font-size: 18px;
        }
    }
}
@media(max-width: 768px){
    .busca {
        &__text {
            font-size: 16px;
        }
        .resultados__list {
            .item {
                &__link {
                    .item__title {
                        font-size: 18px;
                    }
                    .item__text {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
