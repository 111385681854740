.publi {
  .container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;

    .item {
      display: block;
      padding: 15px;

      img {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1499px){
  .publi {
    .container {
      .item {
        img {
         width: 250px;
        }
      }
    }
  }
}