.conduta {
    margin: 30px auto;
    .title{
        color: $white;
        background: $orange;
        font-size: 28px;
        padding: 15px 30px;
        border-radius: 5px;
        margin-bottom: 30px;
    }
    p {
        font-size: 18px;
        line-height: 1.1;
    }
    
        .form-control {
            margin-bottom: 10px;
            border-radius: 5px;
            color: #aaa;
            &::placeholder {
                color: #aaa;
            }
        }
        textarea {
            height: 162px;
        }
        .btn--orange {
            background-color: #E35E12;
            color: #fff;
        }
    .box-form{
        border: solid 1px $gray;
        background: $light-gray;
        padding: 30px;
        .btn{
            display: flex;
            justify-content: center;
        }
    }
}

@media(max-width: 1499px){
    .conduta{
        margin-top: 15px;
        .title{
            font-size: 24px;
            margin-bottom: 15px;
        }
        p{
            font-size: 16px;
        }
    }
}

@media(max-width: 768px){
    .conduta {
        margin-top: 0;
        .title{
            font-size: 20px;
        }
        textarea {
            height: 200px;
        }
        .box-form{
            padding: 15px;
        }
    }
}
@media(max-width: 540px){
    .conduta {
        p{
            font-size: 14px;
        }
    }
}