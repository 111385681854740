.contatos {
    margin-top: 30px;
    .title{
        color: $orange;
        font-size: 28px;
    }
    &__list {
        display: block;
        list-style: none;
        padding: 0;
        .item {
            width: 100%;
            font-size: 18px;
            border: solid 1px $gray;
            padding: 15px;
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 30px;
            .box-title{
                flex-grow: 1;
                width: 40%;
            }
         
            .title{
                color: $orange;
                font-size: 28px;
                font-weight: bold;
                line-height: 1.2;
            }

            .italic{
                font-style: italic;
                text-align: center;
            }
            
            .fone{
                color: $orange;
                font-size: 28px;
                margin: 0;
                line-height: .5;
                text-align: right;
            }
            img {
                display: block;
                width: 21%;
            }
            .btn--green {
                display: block;
                background-color: #87BE49;
                color: #fff;
                padding:  10px;
                border-radius: 999px;
                margin-bottom: 1rem;
                line-height: 25px;
                text-align: center;
                &:hover {
                    text-decoration: none;
                }
            }
            .btn--orange {
                display: block;
                color: $orange;
                padding: 10px 0;
                border-radius: 8px;
                font-size: 16px;
                &:hover {
                    text-decoration: none;
                }
            }
            .link {
                text-decoration: underline;
                color: #00A0DE;
                text-align: right;
                display: block;
            }
            .link--blue {
                display: block;
                text-decoration: underline;
                margin-bottom: 1rem;
            }
        }
    }
}

@media(max-width: 1799px) {
    .contatos{
        .title{
            font-size: 24px;
        }
        &__list{
            .item .fone{
                font-size: 24px;
            }
        }
    }
}
@media(max-width: 1500px) {
    .contatos {
        &__list {
            .item {
                margin-bottom: 30px;
                font-size: 16px;
                .title{
                    font-size: 24px;
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .contatos {
        &__list {
            .item {
                flex-direction: column;
                align-items: flex-start;
                .box-title{
                    width: 100%;
                }
                .fone{
                    text-align: left;
                    font-size: 20px;
                }
                .link{
                    text-align: left;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .contatos {
        &__list {
            .item {
                margin-bottom: 30px;
                font-size: 14px;
                .title{
                    font-size: 20px;
                }
                .btn--green{
                    line-height: 18px;
                }
            }
        }
        .title{
            font-size: 20px;
        }
    }
}
@media (max-width: 540px) {
    .contatos {
        &__list {
            .item {
                margin: 0 auto 30px;
                padding-bottom: 30px;
                img {
                    width: 50%;
                    display: block;
                    margin: 0 auto;
                }
            }
        }
    }
}
