.header {
  padding-top: 25px;

  .container {
    display: flex;
  }
  &__logo {
    position: relative;
    z-index: 2;
    flex: 0 0 350px;
    padding-bottom: 8px;
    margin-right: 40px;

    img {
      max-width: 100%;
    }
  }
  &__wrap {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &-logo {
      display: none;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 25px;
  }
  &__search {
    position: relative;

    input {
      width: 300px;
      height: 42px;
      padding-left: 20px;
      padding-right: 45px;
      color: white;
      font-size: 15px;
      background-color: $orange;
      border: 1px solid transparent;
      border-radius: 999px;

      &::placeholder {
        color: white;
        font-size: 15px;
      }
    }
    button {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 42px;
      height: 42px;
      color: $gray;
      font-size: 19px;
      background-color: transparent;
      border: none;
      border-radius: 100%;
      transition: all .3s;

      &:hover {
        color: white;
        background-color: $orange;
      }
    }
  }
  .icon-padlock {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    margin-left: 10px;
    color: $gray;
    font-size: 22px;
    background-color: $dark-gray;
    border-radius: 100%;
    transition: all .3s;

    &:before {
      transform: translateX(.5px);
    }
    &:hover {
      color: white;
      text-decoration: none;
      background-color: $orange;
    }
  }
  &__menu {
    position: relative;
    padding: 30px 0;
    background-image: url("../img/bg-menu.png");
    background-size: 100% 100%;
    
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 97%;
      width: 15%;
      height: 100%;
      background-image: url("../img/bg-menu_before.png");
      background-size: 100% 100%;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 10%;
      height: 100%;
      background-image: url("../img/bg-menu_after.png");
      background-size: 100% 100%;
    }

    .nav {
      position: relative;
      z-index: 2;
      justify-content: space-between;

      &-link {
        display: block;
        font-size: 22px;
        line-height: 1;
        color: inherit;

        &:hover {
          color: $orange;
        }
      }
    }
  }
  &__toggle-menu {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 25px;

    .bar {
      position: relative;
      z-index: 100;
      width: 40px;
      height: 5px;
      background-color: $orange;
      border-radius: 4px;
      transition: all .3s;
    }
    &.open {
      .bar {
        &:nth-child(1) {
          width: 35px;
          transform: rotate(45deg) translate(8px, 7px);
        }
        &:nth-child(2) {
          width: 0;
        }
        &:nth-child(3) {
          width: 35px;
          transform: rotate(-45deg) translate(7px, -7px);
        }
      }
      &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        width: 100%;
        height: 100%;
        background-color: rgba(black, .8);
      }
    }
  }
}
.detail-purple{
  .header__search input{
    background-color: $purple;
  }
}

.detail-blue{
  .header__search input{
    background-color: $teal;
  }
}
.detail-yellow{
  .header__search input{
    background-color: #ffcc00;
  }
}

@media (max-width: 1799px) {
  .header {
    &__logo {
      flex: 0 0 300px;
      padding-bottom: 0;
    }
    &__top {
      padding-bottom: 15px;
    }
    &__search {
      input {
        width: 260px;
        height: 40px;
        padding-left: 20px;
        padding-right: 42px;
        font-size: 14px;
  
        &::placeholder {
          font-size: 14px;
        }
      }
      button {
        width: 40px;
        height: 40px;
        font-size: 18px;
      }
    }
    .icon-padlock {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }
    &__menu {
      padding: 20px 0;

      .nav {
        &-link {
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 1499px) {
  .header {
    &__logo {
      flex: 0 0 260px;
    }
    &__search {
      input {
        width: 260px;
        height: 38px;
        padding-left: 18px;
        padding-right: 40px;
      }
      button {
        width: 38px;
        height: 38px;
        font-size: 16px;
      }
    }
    .icon-padlock {
      width: 38px;
      height: 38px;
      font-size: 18px;
    }
    &__menu {
      .nav {
        &-link {
          font-size: 18px;
        }
      }
    }
  }
}
@media (max-width: 1199px) {
  .header {
    &__logo {
      flex: 0 0 240px;
    }
    &__menu {
      .nav {
        &-link {
          font-size: 18px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .header {
    &__logo {
      flex: 0 0 200px;
      margin-right: 15px;
    }
    &__menu {
      .nav {
        &-link {
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .header {
    position: relative;
    padding-top: 15px;
    padding-bottom: 62px;

     &__logo {
      flex: 0 0 160px;
     }
     &__wrap {
      justify-content: center;
     }
     &__top {
      padding-bottom: 0;
     }
     &__search {
      input {
        width: 220px;
      }
     }
     &__menu {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 8px 0;

      &:before,
      &:after {
        content: none;
      }
      .nav {
        max-width: 540px;
        padding-left: 5px;
        padding-right: 5px;
        margin: 0 auto;

        &-link {
          font-size: 16px;
        }
      }
     }
  }
}
@media (max-width: 575px) {
  .header {
    padding-bottom: 15px;
    
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &__search {
      input {
        width: 150px;
        height: 34px;
        padding-left: 15px;
        padding-right: 36px;
        font-size: 13px;

        &::placeholder {
          font-size: 13px;
        }
      }
      button {
        width: 34px;
        height: 34px;
        font-size: 15px;
      }
    }
    .icon-padlock {
      width: 34px;
      height: 34px;
      font-size: 17px;
    }
    &__wrap {
      position: fixed;
      top: 0;
      left: 0;
      transform: translateX(-100%);
      z-index: 100;
      align-items: flex-start;
      justify-content: flex-start;
      width: 290px;
      height: 100%;
      padding: 15px;
      background-color: white;
      transition: transform .4s;

      &.open {
        transform: translateX(0);
      }
      &-logo {
        display: block;
        width: 160px;
        padding-bottom: 15px;

        img {
          max-width: 100%;
        }
      }
    }
    &__top {
      margin-bottom: 20px;
    }
    &__search {

      input {
        width: 208px;
        height: 42px;
        padding-left: 20px;
        padding-right: 45px;
        font-size: 15px;
  
        &::placeholder {
          font-size: 15px;
        }
      }
      button {
        width: 42px;
        height: 42px;
        font-size: 19px;
      }
    }
    .icon-padlock {
      width: 42px;
      height: 42px;
      font-size: 22px;
    }
    &__menu {
      position: static;
      bottom: auto;
      display: block;
      background-image: none;

      .nav {
        flex-direction: column;

        &-item {
          &:not(:last-child) {
            border-bottom: 1px solid $gray;
          }
        }
        &-link {
          padding: 15px;
          font-size: 21px;
        }
      }
    }
    &__toggle-menu {
      display: flex;
    }
  }
}