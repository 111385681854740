.enxaqueca {
    margin-top: 30px;
    .back {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__title {
            display: flex;
            align-items: center;
            font-size: 32px;
            &::before {
                content: "";
                display: block;
                background: url('../img/img-menu-purple.png');
                background-repeat: no-repeat;
                background-size: contain;
                width: 30px;
                height: 30px;
            }
        }
        .btn {
            background-color: #D5D5D5;
            color: #605D5C;
            font-size: clamp(16px, 2vw, 20px);
            text-transform: none;
            font-weight: normal;
        }
    }
    &__banner {
        margin-top: 30px;
    }
    &__text {
        font-size: 20px;
        margin-block: 20px;
    }
    &__info-buy {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        .saiba-mais {
            font-size: 20px;
            color: #00A0DE;
            text-decoration: underline;
        }
        .btn-buy {
            color: #fff;
            display: block;
            font-size: clamp(16px, 2vw, 20px);
            background-color: #7DD518;
            padding: 8px 40px;
            border-radius: 99999px;
            text-transform: uppercase;
            &:hover {
                text-decoration: none;
            }
        }
    }
    &__endereco {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .endereco {
            font-size: 20px;
        }
        .site {
            &__link {
                font-size: 20px;
                color: #00A0DE;
                text-decoration: underline;
            }
        }
    }

    &__cefaly {
        .cefaly__title {
            display: flex;
            align-items: center;
            margin-top: 30px;
            margin-bottom: 30px;
            font-size: 32px;
        &::before {
            content: "";
            display: block;
            background: url('../img/img-menu-purple.png');
            background-repeat: no-repeat;
            background-size: contain;
            width: 30px;
            height: 30px;
            }
        }
    }
    .cefaly__link {
        display: flex;
        align-items: center;
        font-size: 20px;
        width: max-content;
        &:hover {
            text-decoration: none;
            color: #A865C4;
        }
        &:hover img {
            border: 1px solid #A865C4;
            border-radius: 8px;
        }
        img {
            margin-right: 30px;
            display: block;
            max-width: 180px;
            border: 1px solid #d5d5d5;
            border-radius: 5px;
        }
    }
}

@media(max-width: 1440px) {
    .enxaqueca {
        .back {
            &__title {
                font-size: 28px;
            }
        }
        &__cefaly {
            .cefaly__title {
                font-size: 28px;
            }
        }
    }
}
@media(max-width: 1024px) {
    .enxaqueca {
        .back {
            &__title {
                font-size: 24px;
            }
        }
        &__text {
            font-size: 18px;
        }
        &__info-buy {
            .saiba-mais {
                font-size: 18px;
            }
        }
        &__endereco {
            .endereco {
                p {
                    font-size: 18px;
                }
            }
            .site {
                &__link {
                    font-size: 18px;
                }
            }
        }
        &__cefaly {
            .cefaly__title {
                font-size: 24px;
            }
            .cefaly__link {
                font-size: 18px;
            }
        }
    }
}
@media(max-width: 768px) {
    .enxaqueca {
        .back {
            &__title {
                font-size: 22px;
            }
        }
        &__cefaly {
            .cefaly__title {
                font-size: 22px;
            }
        }
    }
}
@media(max-width: 540px) {
    .enxaqueca {
        .back {
            &__title {
                font-size: 20px;
            }
        }
        &__text {
            font-size: 16px;
        }
        &__info-buy {
            .saiba-mais {
                font-size: 16px;
            }
        }
        &__endereco {
            .endereco {
                p {
                    font-size: 16px;
                }
            }
            .site {
                &__link {
                    font-size: 16px;
                }
            }
        }
        &__cefaly {
            .cefaly__title {
                font-size: 20px;
            }
            .cefaly__link {
                font-size: 16px;
            }
        }
    }
}