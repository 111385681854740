@media (min-width: 1500px) {
  .container {
    max-width: 1300px;
  }
  .col-xxl-1 {
    flex: 0 0 calc((100% / 24) * 1);
    max-width: calc((100% / 24) * 1);
  }
  .col-xxl-2 {
    flex: 0 0 calc((100% / 24) * 2);
    max-width: calc((100% / 24) * 2);
  }
  .col-xxl-3 {
    flex: 0 0 calc((100% / 24) * 3);
    max-width: calc((100% / 24) * 3);
  }
  .col-xxl-4 {
    flex: 0 0 calc((100% / 24) * 4);
    max-width: calc((100% / 24) * 4);
  }
  .col-xxl-5 {
    flex: 0 0 calc((100% / 24) * 5);
    max-width: calc((100% / 24) * 5);
  }
  .col-xxl-6 {
    flex: 0 0 calc((100% / 24) * 6);
    max-width: calc((100% / 24) * 6);
  }
  .col-xxl-7 {
    flex: 0 0 calc((100% / 24) * 7);
    max-width: calc((100% / 24) * 7);
  }
  .col-xxl-8 {
    flex: 0 0 calc((100% / 24) * 8);
    max-width: calc((100% / 24) * 8);
  }
  .col-xxl-9 {
    flex: 0 0 calc((100% / 24) * 9);
    max-width: calc((100% / 24) * 9);
  }
  .col-xxl-10 {
    flex: 0 0 calc((100% / 24) * 10);
    max-width: calc((100% / 24) * 10);
  }
  .col-xxl-11 {
    flex: 0 0 calc((100% / 24) * 11);
    max-width: calc((100% / 24) * 11);
  }
  .col-xxl-12 {
    flex: 0 0 calc((100% / 24) * 12);
    max-width: calc((100% / 24) * 12);
  }
  .col-xxl-13 {
    flex: 0 0 calc((100% / 24) * 13);
    max-width: calc((100% / 24) * 13);
  }
  .col-xxl-14 {
    flex: 0 0 calc((100% / 24) * 14);
    max-width: calc((100% / 24) * 14);
  }
  .col-xxl-15 {
    flex: 0 0 calc((100% / 24) * 15);
    max-width: calc((100% / 24) * 15);
  }
  .col-xxl-16 {
    flex: 0 0 calc((100% / 24) * 16);
    max-width: calc((100% / 24) * 16);
  }
  .col-xxl-17 {
    flex: 0 0 calc((100% / 24) * 17);
    max-width: calc((100% / 24) * 17);
  }
  .col-xxl-18 {
    flex: 0 0 calc((100% / 24) * 18);
    max-width: calc((100% / 24) * 18);
  }
  .col-xxl-19 {
    flex: 0 0 calc((100% / 24) * 19);
    max-width: calc((100% / 24) * 19);
  }
  .col-xxl-20 {
    flex: 0 0 calc((100% / 24) * 20);
    max-width: calc((100% / 24) * 20);
  }
  .col-xxl-21 {
    flex: 0 0 calc((100% / 24) * 21);
    max-width: calc((100% / 24) * 21);
  }
  .col-xxl-22 {
    flex: 0 0 calc((100% / 24) * 22);
    max-width: calc((100% / 24) * 22);
  }
  .col-xxl-23 {
    flex: 0 0 calc((100% / 24) * 23);
    max-width: calc((100% / 24) * 23);
  }
  .col-xxl-24 {
    flex: 0 0 calc((100% / 24) * 24);
    max-width: calc((100% / 24) * 24);
  }
  .offset-xxl-1 {
    margin-left: calc((100% / 24) * 1);
  }
  .offset-xxl-2 {
    margin-left: calc((100% / 24) * 2);
  }
  .offset-xxl-3 {
    margin-left: calc((100% / 24) * 3);
  }
  .offset-xxl-4 {
    margin-left: calc((100% / 24) * 4);
  }
  .offset-xxl-5 {
    margin-left: calc((100% / 24) * 5);
  }
  .offset-xxl-6 {
    margin-left: calc((100% / 24) * 6);
  }
  .offset-xxl-7 {
    margin-left: calc((100% / 24) * 7);
  }
  .offset-xxl-8 {
    margin-left: calc((100% / 24) * 8);
  }
  .offset-xxl-9 {
    margin-left: calc((100% / 24) * 9);
  }
  .offset-xxl-10 {
    margin-left: calc((100% / 24) * 10);
  }
  .offset-xxl-11 {
    margin-left: calc((100% / 24) * 11);
  }
  .offset-xxl-12 {
    margin-left: calc((100% / 24) * 12);
  }
  .offset-xxl-13 {
    margin-left: calc((100% / 24) * 13);
  }
  .offset-xxl-14 {
    margin-left: calc((100% / 24) * 14);
  }
  .offset-xxl-15 {
    margin-left: calc((100% / 24) * 15);
  }
  .offset-xxl-16 {
    margin-left: calc((100% / 24) * 16);
  }
  .offset-xxl-17 {
    margin-left: calc((100% / 24) * 17);
  }
  .offset-xxl-18 {
    margin-left: calc((100% / 24) * 18);
  }
  .offset-xxl-19 {
    margin-left: calc((100% / 24) * 19);
  }
  .offset-xxl-20 {
    margin-left: calc((100% / 24) * 20);
  }
  .offset-xxl-21 {
    margin-left: calc((100% / 24) * 21);
  }
  .offset-xxl-22 {
    margin-left: calc((100% / 24) * 22);
  }
  .offset-xxl-23 {
    margin-left: calc((100% / 24) * 23);
  }
}
@media (min-width: 1800px) {
  .container {
    max-width: 1550px;
  }
  .col-xxxl-1 {
    flex: 0 0 calc((100% / 24) * 1);
    max-width: calc((100% / 24) * 1);
  }
  .col-xxxl-2 {
    flex: 0 0 calc((100% / 24) * 2);
    max-width: calc((100% / 24) * 2);
  }
  .col-xxxl-3 {
    flex: 0 0 calc((100% / 24) * 3);
    max-width: calc((100% / 24) * 3);
  }
  .col-xxxl-4 {
    flex: 0 0 calc((100% / 24) * 4);
    max-width: calc((100% / 24) * 4);
  }
  .col-xxxl-5 {
    flex: 0 0 calc((100% / 24) * 5);
    max-width: calc((100% / 24) * 5);
  }
  .col-xxxl-6 {
    flex: 0 0 calc((100% / 24) * 6);
    max-width: calc((100% / 24) * 6);
  }
  .col-xxxl-7 {
    flex: 0 0 calc((100% / 24) * 7);
    max-width: calc((100% / 24) * 7);
  }
  .col-xxxl-8 {
    flex: 0 0 calc((100% / 24) * 8);
    max-width: calc((100% / 24) * 8);
  }
  .col-xxxl-9 {
    flex: 0 0 calc((100% / 24) * 9);
    max-width: calc((100% / 24) * 9);
  }
  .col-xxxl-10 {
    flex: 0 0 calc((100% / 24) * 10);
    max-width: calc((100% / 24) * 10);
  }
  .col-xxxl-11 {
    flex: 0 0 calc((100% / 24) * 11);
    max-width: calc((100% / 24) * 11);
  }
  .col-xxxl-12 {
    flex: 0 0 calc((100% / 24) * 12);
    max-width: calc((100% / 24) * 12);
  }
  .col-xxxl-13 {
    flex: 0 0 calc((100% / 24) * 13);
    max-width: calc((100% / 24) * 13);
  }
  .col-xxxl-14 {
    flex: 0 0 calc((100% / 24) * 14);
    max-width: calc((100% / 24) * 14);
  }
  .col-xxxl-15 {
    flex: 0 0 calc((100% / 24) * 15);
    max-width: calc((100% / 24) * 15);
  }
  .col-xxxl-16 {
    flex: 0 0 calc((100% / 24) * 16);
    max-width: calc((100% / 24) * 16);
  }
  .col-xxxl-17 {
    flex: 0 0 calc((100% / 24) * 17);
    max-width: calc((100% / 24) * 17);
  }
  .col-xxxl-18 {
    flex: 0 0 calc((100% / 24) * 18);
    max-width: calc((100% / 24) * 18);
  }
  .col-xxxl-19 {
    flex: 0 0 calc((100% / 24) * 19);
    max-width: calc((100% / 24) * 19);
  }
  .col-xxxl-20 {
    flex: 0 0 calc((100% / 24) * 20);
    max-width: calc((100% / 24) * 20);
  }
  .col-xxxl-21 {
    flex: 0 0 calc((100% / 24) * 21);
    max-width: calc((100% / 24) * 21);
  }
  .col-xxxl-22 {
    flex: 0 0 calc((100% / 24) * 22);
    max-width: calc((100% / 24) * 22);
  }
  .col-xxxl-23 {
    flex: 0 0 calc((100% / 24) * 23);
    max-width: calc((100% / 24) * 23);
  }
  .col-xxxl-24 {
    flex: 0 0 calc((100% / 24) * 24);
    max-width: calc((100% / 24) * 24);
  }
  .offset-xxxl-1 {
    margin-left: calc((100% / 24) * 1);
  }
  .offset-xxxl-2 {
    margin-left: calc((100% / 24) * 2);
  }
  .offset-xxxl-3 {
    margin-left: calc((100% / 24) * 3);
  }
  .offset-xxxl-4 {
    margin-left: calc((100% / 24) * 4);
  }
  .offset-xxxl-5 {
    margin-left: calc((100% / 24) * 5);
  }
  .offset-xxxl-6 {
    margin-left: calc((100% / 24) * 6);
  }
  .offset-xxxl-7 {
    margin-left: calc((100% / 24) * 7);
  }
  .offset-xxxl-8 {
    margin-left: calc((100% / 24) * 8);
  }
  .offset-xxxl-9 {
    margin-left: calc((100% / 24) * 9);
  }
  .offset-xxxl-10 {
    margin-left: calc((100% / 24) * 10);
  }
  .offset-xxxl-11 {
    margin-left: calc((100% / 24) * 11);
  }
  .offset-xxxl-12 {
    margin-left: calc((100% / 24) * 12);
  }
  .offset-xxxl-13 {
    margin-left: calc((100% / 24) * 13);
  }
  .offset-xxxl-14 {
    margin-left: calc((100% / 24) * 14);
  }
  .offset-xxxl-15 {
    margin-left: calc((100% / 24) * 15);
  }
  .offset-xxxl-16 {
    margin-left: calc((100% / 24) * 16);
  }
  .offset-xxxl-17 {
    margin-left: calc((100% / 24) * 17);
  }
  .offset-xxxl-18 {
    margin-left: calc((100% / 24) * 18);
  }
  .offset-xxxl-19 {
    margin-left: calc((100% / 24) * 19);
  }
  .offset-xxxl-20 {
    margin-left: calc((100% / 24) * 20);
  }
  .offset-xxxl-21 {
    margin-left: calc((100% / 24) * 21);
  }
  .offset-xxxl-22 {
    margin-left: calc((100% / 24) * 22);
  }
  .offset-xxxl-23 {
    margin-left: calc((100% / 24) * 23);
  }
}