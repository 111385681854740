.paginacao {
  text-align: right;
  margin-top: 60px;
  .prev {
    background-color: transparent;
    font-size: 24px;
    color: #d5d5d5;
    padding-left: 0;
    &:hover {
      background-color: transparent;
      color: #d5d5d5;
    }
  }
  a {
    padding: 16px 20px;
    background-color: #d5d5d5;
    border-radius: 8px;
    &:hover {
      background-color: #5d9b16;
      color: #fff;
      text-decoration: none;
    }
  }
  .active--green {
    background-color: #5d9b16;
    color: #fff;
  }
  .active--purple {
    background-color: #a865c4;
    color: #fff;
  }
  .active--orange {
    background-color: #E35E12;
    color: #fff;
  }
  .next {
    background-color: transparent;
    font-size: 24px;
    color: #d5d5d5;
    &:hover {
      background-color: transparent;
      color: #d5d5d5;
    }
  }
}

.hover--purple {
  a {
    &:hover {
      background-color: #a865c4;
      color: #fff;
      text-decoration: none;
    }
  }
}

.hover--orange {
  a {
    &:hover {
      background-color: #E35E12;
      color: #fff;
      text-decoration: none;
    }
  }
}

.align--left {
  text-align: left;
}
