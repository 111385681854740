.solucoes-auditivas {
    margin-top: 30px;
    &__banner {
        margin-top: 30px;
    }
    &__subtitle {
        font-size: 24px;
        &-purple{
            color: $purple;
            font-weight: bold;
        }
    }
    &__column {
        list-style: none;
        padding: 0;
        .item {
            margin-bottom: 15px;
            img {
                width: 100%;
            }
        }
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 25px;
        list-style: none;
        padding: 0;
        .item {
            margin-bottom: 24px;
            &__link {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                gap: 15px;
                font-size: clamp(16px, 2vw, 22px);
                img {
                    width: 100%;
                    border: 1px solid #d5d5d5;
                    border-radius: 5px;
                    padding: 20px;
                }
                &:hover {
                    text-decoration: none;
                }
                &:hover > img {
                    border: 1px solid #A865C4;
                    border-radius: 5px;
                }
                &:hover > strong {
                    color: #A865C4;
                    text-decoration: none;
                }
            }
        }
    }
    &__list-som{
        padding-left: 5px;
    }
    .text-link{
        color: #006FFF;
        text-decoration: underline;
        &.font{
            font-size: 18px;
        }
    }
    &__programas{
        .box{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-bottom: 15px;
         
            &__text{
                padding: 5px 15px;
                border-radius: 5px;
                width: 100%;
                &.blue{
                    background: #D9F2FF;
                }
                &.orange{
                    background: #FFEBD9;
                    color: $orange;
                }
                &.green{
                    background: #EFFFD9;
                }
                &.yellow{
                    background: #FFF9D9;
                }
                .info{
                    margin-bottom: 0;
                }
            }
        }
    }
    &__lojas{
        border: solid 1px $gray;
        border-radius: 5px;
        padding: 15px;
        display: flex;
        align-items: center;
        gap: 35px;
        .title{
            color: $orange;
            font-weight: bold;
            font-size: 28px;
            margin-bottom: 10px;
            margin-top: 20px;
        }
    }
}

@media(min-width: 1500px){

}
@media(max-width: 1499px) {
    .solucoes-auditivas {
        .back {
            &__title {
                font-size: 28px;
            }
        }
    }
}
@media (max-width: 1199px) {
    .solucoes-auditivas {
        &__list {
        grid-template-columns: repeat(4, 1fr);
        }
        &__lojas{
            .title{
                font-size: 24px;
            }
        }
    }
}
@media(max-width: 1024px) {
    .solucoes-auditivas {
        .back {
            &__title {
                font-size: 24px;
            }
        }
        &__subtitle {
            font-size: 22px;
        }
    }
}
@media(max-width: 768px) {
    .solucoes-auditivas {
        .back {
            &__title {
                font-size: 22px;
            }
        }
        &__subtitle {
            font-size: 20px;
        }
        &__list-som{
            padding-left: 15px;
        }
        &__list {
            .item {
                &__link {
                    img {
                        padding: 10px;
                    }
                }
            }
        }
        &__lojas{
            .title{
                font-size: 20px;
            }
            .text-link .font{
                font-size: 16px;
            }
        }
    }
}
@media (max-width: 540px) {
    .solucoes-auditivas {
        .back {
            &__title {
                font-size: 20px;
            }
        }
        &__list {
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
        }

        &__subtitle {
            font-size: 18px;
        }
        &__lojas{
            flex-direction: column-reverse;
            .title{
                margin-top: 0;
            }
        }
        
    }
}
@media (max-width: 475px) {
    .solucoes-auditivas {
        &__list {
        grid-template-columns: repeat(3, 1fr);
        }
    }
}
@media (max-width: 375px) {
    .solucoes-auditivas {
        &__list {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 15px;
        }
    }
}