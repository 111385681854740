.aside__produtos {
    margin-top: 30px;
      .categorias {
        .title{
          color: $white;
          background: $purple;
          padding: 10px 30px;
          font-size: 28px;
          border-radius: 5px;
        }
        &__list {
          list-style: none;
          padding: 0;
          .item {
            &__link {
              display: block;
              font-size: 18px;
              padding: 4px 4px;
              text-align: right;
              border-bottom: 1px solid $gray;
              &:hover {
                color: $purple;
                text-decoration: none;
                font-weight: bold;
              }
            }
            .active {
                color: #fff;
                text-decoration: none;
                background-color: #A865C4;
                padding: 4px 8px;
            }
          }
        }
      }
    }

@media(max-width: 1440px) {
  .aside__produtos {
      .categorias {
        .title{
          font-size: 24px;
        }
        &__list {
          .item {
            &__link {
              font-size: 16px;
            }
          }
        }
      }
    }
}
@media(max-width: 991px){
  .aside__produtos .categorias .title{
    font-size: 20px;
    padding: 10px 15px;
  }
}

@media(max-width: 768px) {
  .aside__produtos {
      .categorias {
        &__list {
          .item {
            &__link {
              font-size: 16px;
            }
          }
        }
      }
    }
}

@media(max-width: 540px) {
  .aside__produtos {
    margin-top: 10px;
    .categorias {
      .title{
        font-size: 18px;
      }
      &__list {
        border-radius: 5px;
      }
    }
  }
}
