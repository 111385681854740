.distribuidores {
    .back {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__title {
            display: flex;
            align-items: center;
            margin-top: 50px;
            margin-bottom: 30px;
            font-size: 32px;
        }
        .btn {
            background-color: #D5D5D5;
            color: #605D5C;
            font-size: 20px;
            text-transform: none;
            font-weight: normal;
        }
    }

    .divisao {
        display: flex;
        align-items: center;
        label {
            display: block;
            font-size: 22px;
            width: 50%;
            font-weight: bold;
        }
        select {
            border-color: #AAAAAA;
            border-radius: 5px;
            height: 37px;
            width: 100%;
            padding-inline: 10px;
            color: #AAAAAA;
        }
    }
  
    .regiao {
        background-color: $light-gray;
        h3 {
            background-color: #2DADC0;
            color: #fff;
            font-size: 20px;
            padding: 10px 34px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        &__cidades {
            display: flex;
            align-items: center;
            padding: 0 15px;
            label {
                font-size: 20px;
                margin-right: 15px;
            }
            select {
                border-radius: 5px;
                border-color: #AAAAAA;
                height: 37px;
                width: 343px;
                padding-inline: 10px;
                color: #AAAAAA;
            }
        }
        &__resultados {
            max-height: 325px;
            overflow-y: scroll;
            dl {
                margin-left: 15px;
                font-size: 18px;
                line-height: 1.2;
                border-bottom: 1px solid #D5D5D5;
                &:last-child {
                    border-bottom: 1px solid transparent;
                }
                dt {
                    margin-top: 20px;
                    margin-bottom: 5px;
                    text-transform: uppercase;
                }
                .tel {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    &::before {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 12px;
                        background: url('../img/icon-phone.svg');
                        background-repeat: no-repeat;
                    }
                }
                .contato {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    &::before {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 12px;
                        background: url('../img/icon-email.svg');
                        background-repeat: no-repeat;
                    }
                }
                .endereco {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    &::before {
                        content: "";
                        display: block;
                        width: 20px;
                        height: 20px;
                        background: url('../img/icon-pin.svg');
                        background-repeat: no-repeat;
                    }
                }
            }
        }        
    }
    &.lojas{
         .back__title{
            color: #ffcc00;
            font-weight: bold;
            margin-top: 15px;
         }

         .regiao h3{
            background: #ffcc00;
         }
         .regiao{
            &__resultados dl {
                .tel {
                    &::before {
                        background: url('../img/Ico-phone-yellow.png');
                        background-repeat: no-repeat;

                    }
                }
                .contato {
                    &::before {
                        background: url('../img/Ico-email-yellow.png');
                        background-repeat: no-repeat;

                    }
                }
                .endereco {
                    &::before {
                        background: url('../img/Ico-location-yellow.png');
                        background-repeat: no-repeat;

                    }
                }
            }
         }
    }
}

.detail-blue {
    .divisao label{
        color: $teal;
    }
} 

@media(max-width: 1440px){
    .distribuidores {
        .back {
            &__title {
                font-size: 28px;
            }
        }
    }
}


@media(max-width: 1024px){
    .distribuidores {
        .back {
            &__title {
                font-size: 24px;
            }
            .btn {
                font-size: 18px;
            }
        }
        .divisao {
            label {
                font-size: 18px;
            }
        }
        .regiao {
            h3 {
                font-size: 18px;
            }
            &__cidades {
                label {
                    font-size: 18px;
                }
            }
            &__resultados {
                dl {
                    dt {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
@media(max-width: 768px){
    .distribuidores {
        .back {
            &__title {
                font-size: 20px;
            }
            .btn {
                font-size: 16px;
            }
        }
    }
}
@media(max-width: 540px){
    .distribuidores {
        .back {
            &__title {
                font-size: 18px;
            }
        }
        .divisao {
            label {
                font-size: 16px;
            }
        }
        .regiao {
            h3 {
                font-size: 16px;
            }
            &__cidades {
                label {
                    font-size: 16px;
                }
            }
            &__resultados {
                dl {
                    dt {
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
