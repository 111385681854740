.imprensa {
  margin-top: 40px;
  &__items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 50px;
    .item {
      display: flex;
      flex-direction: column;
      margin-bottom: 40px;
      &:hover {
        color: $orange;
      }
      &__img {
        width: 100%;
        border: 1px solid #d5d5d5;
        border-radius: 5px;
        padding: 10px;
      }
      &:hover > .item__img {
        border: 1px solid $orange;
        border-radius: 5px;
      }
      &__desc {
        display: block;
        margin-top: 5px;
        font-size: 16px;
        background: $light-gray;
        padding: 15px;
        flex-grow: 1;
        border-radius: 10px;
        .date {
          display: block;
          color: $dark-gray;
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 1440px) {
  .imprensa {
    &__items {
      grid-column-gap: 40px;
    }
  }
}
@media (max-width: 1200px) {
  .imprensa {
    &__items {
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 50px;
    }
  }
}
@media (max-width: 1024px) {
  .imprensa {
    &__items {
      grid-column-gap: 30px;
    }
  }
}
@media (max-width: 768px) {
  .imprensa {
    &__items {
      grid-column-gap: 15px;
      .item {
        &__desc {
          font-size: 16px;
        }
      }
    }
  }
}
@media (max-width: 540px) {
  .imprensa {
    &__items {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}