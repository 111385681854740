.intro {
  padding: 0 0 35px;
  text-align: center;

  &__txt {
    max-width: 1100px;
    margin: 0 auto;
    font-size: 20px;
    line-height: 1.6;
  }
}

@media (max-width: 1199px) {
  .intro {
    &__txt {
      font-size: 18px;
    }
  }
}
@media (max-width: 991px) {
  .intro {
    padding-bottom: 25px;
  }
}
@media (max-width: 575px) {
  .intro {
    padding: 25px 0;
  }
}