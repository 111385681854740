.assistencia {
    max-width: 500px;
    margin: 30px auto;
    
        .controle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 30px;
            &__links {
                a {
                    display: block;
                    color: #00A0DE;
                    text-decoration: underline;
                }
            }
            &__submit {
                color: #fff;
                background-color: #FFA304;
                border: 1px solid transparent;
                border-radius: 99999px;
                padding: 10px 30px;
            }
        }
    
}