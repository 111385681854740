.empresa {
    &__banner {
        img {
            display: block;
            width: 100%;
        }
    }
    .sobre {
        margin-top: 40px;
        &__title {
            font-size: 32px;
            color: $orange;
            margin-top: 30px;
        }
        &__text {
            font-size: 20px;
        }
        &__text--mb0 {
            margin-bottom: 0;
        }
    }
}

@media(max-width: 1440px) {
    .empresa {
        .sobre {
            &__title {
                font-size: 28px;
            }
            &__text {
                font-size: 16px;
            }
        }
    }
}
@media(max-width: 1024px) {
    .empresa {
        .sobre {
            &__title {
                font-size: 24px;
            }
        }
    }
}
@media(max-width: 768px) {
    .empresa {
        .sobre {
            &__title {
                font-size: 22px;
            }
        }
    }
}
@media(max-width: 540px) {
    .empresa {
        .sobre {
            &__title {
                font-size: 20px;
            }
            &__text {
                font-size: 14px;
            }
        }
    }
}