.footer {
  padding: 15px 0;
  margin-top: 60px;
  background-color: #EFEFEF;
  border-top: 1px solid $dark-gray;

  .row {
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  &__logo {
    padding: 15px;

    img {
      max-width: 100%;
    }
  }
  &__wrap {
    padding: 15px;
  }
  &__nav {
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-bottom: 10px;
    list-style: none;

    .nav {
      &-item {
        display: flex;
        align-items: center;
        padding: 5px 0;

        + .nav-item {

          .nav-link {
            border-left: 1px solid $font-color;
          }
        }
      }
      &-link {
        padding: 0 8px;
        font-size: 17px;
        line-height: .8;
        text-align: center;

        &:hover {
          color: $orange;
        }
      }
    }
  }
  &__copyright {
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
  }
  .text-right {
    min-width: 105px;
    padding: 15px;
  }
  .icon-vm2 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-height: 20px;
    font-size: 40px;
    overflow: hidden;

    &:hover {
      color: #a50000;
      text-decoration: none;
    }
  }
  &__end {
    display: block;
    font-style: italic;
  }
}

@media (max-width: 1799px) {
  .footer {
    &__logo {
      flex: 0 0 230px;
    }
    &__nav {
      .nav {
        &-link {
          padding: 0 6px;
          font-size: 16px;
        }
      }
    }
    &__copyright {
      font-size: 13px;
    }
  }
}
@media (max-width: 1499px) {
  .footer {
    &__logo {
      flex: 0 0 195px;
      padding: 15px 10px;
    }
    .row {
      margin: 0 -10px;
    }
    &__wrap {
      padding: 15px 10px;
    }
    &__nav {
      margin-right: -5px;
      margin-left: -5px;

      .nav {
        &-link {
          padding: 0 5px;
          font-size: 15px;
        }
      }
    }
    &__copyright {
      font-size: 13px;
    }
    .text-center {
      padding: 15px 10px;
    }
    &__end {
      font-size: 13px;
    }
  }
}
@media (max-width: 1199px) {
  .footer {
    &__nav {
      flex-wrap: wrap;
      margin-right: -5px;
      margin-left: -5px;

      .nav {
        &-link {
          padding: 0 5px;
          font-size: 15px;
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .footer {
    .row {
      flex-direction: column;
    }
    &__logo {
      flex: 0 0 auto;
    }
    .text-right {
      text-align: center !important;
    }
  }
}