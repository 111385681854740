.cadastro {
  max-width: 900px;
  margin: 0 auto;
  .campos__obrigatorios {
    font-size: 14px;
    color: #CD0A0A;
    margin-top: 20px;
  }
  &__title {
    display: flex;
    align-items: center;
    font-size: 32px;
    margin-block: 30px;
    &::before {
      content: "";
      display: block;
      background: url("../img/img-menu-darkorange.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 30px;
      height: 30px;
    }
  }
  .info {
    font-size: 18px;
    color: red;
  }
  &__secao {
    font-size: 22px;
    margin-bottom: 10px;
    color: #ffcc00;
    font-weight: bold;
  }
  .fieldset__wrapper {
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding: 20px;
    width: 100%;
    margin-bottom: 20px;
    legend {
      padding-left: 15px;
    }
    .wrap {
      display: grid;
      grid-template-columns: 175px 1fr;
      grid-column-gap: 10px;
      margin-bottom: 10px;
      align-items: center;
      label {
        font-size: 16px;
        text-align: right;
      }
      select {
        color: #8a8a8a;
      }
    }
    .wrap--3 {
      display: grid;
      grid-template-columns: 175px 1fr 0.75fr;
      grid-column-gap: 10px;
      margin-bottom: 10px;
      align-items: center;
      label {
        font-size: 16px;
        text-align: right;
      }
      a {
        color: #ffcc00;
        text-decoration: underline;
      }
      span {
        font-size: 14px;
      }
    }
    .wrap--small {
      grid-template-columns: 90px 1fr;
    }
    .form-select {
      display: block;
      width: 100%;
      padding: 0.375rem 2.25rem 0.375rem 0.75rem;
      -moz-padding-start: calc(0.75rem - 3px);
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-image: url("../img/chevron-down.svg");
      background-repeat: no-repeat;
      background-position: right 0.75rem center;
      background-size: 16px 12px;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  .btn--yellow {
    background-color: #ffcc00;
    color: #fff;
    justify-content: flex-end;
    border: 1px solid transparent;
    border-radius: 99999px;
    font-size: 18px;
    padding: 10px 30px;
  }
}

@media(max-width: 1440px) {
  .cadastro {
    &__title {
      font-size: 28px;
    }
  }
}
@media(max-width: 1024px) {
  .cadastro {
    &__title {
      font-size: 24px;
    }
    .info {
      font-size: 16px;
    }
    
    &__secao {
      font-size: 20px;
    }
    .btn--orange {
      font-size: 18px;
    }
  }
}
@media(max-width: 768px) {
  .cadastro {
    &__title {
      font-size: 22px;
    }
  }
}
@media (max-width: 576px) {
  .cadastro {
    &__title {
      font-size: 20px;
    }
    .fieldset__wrapper {
      .wrap {
        grid-template-columns: 1fr;
        label {
          text-align: left;
          font-size: 16px;
        }
      }
      .wrap--3 {
        grid-template-columns: 1fr;
        label {
          text-align: left;
          font-size: 16px;
        }
      }
    }
    .btn--orange {
      font-size: 16px;
    }
  }
}
