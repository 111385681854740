.box-title-purple{
    padding: 10px 30px;
    border-radius: 5px;
    background: $purple;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    .btn-voltar{
        color: $dark-gray;
        background: $white;
        padding: 2px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 18px;
        line-height: 1.5;
        &:hover{
            text-decoration: none;
        }
        .arrow{
            padding-top: 5px;
        }
    }
}
.produtos {
    margin-top: 30px;
    &__title {
        display: flex;
        align-items: center;
        font-size: 28px;
        color: $white;
        margin-bottom: 0;
    }
    &__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 50px;
        margin-top: 30px;
        list-style: none;
        padding: 0;
        .item {
            display: block;
            margin-bottom: 40px;
            &__link {
                display: block;
                width: 100%;
                img{
                    display: block;
                    width: 100%;
                    border: 1px solid #D5D5D5;
                    border-radius: 10px;
                    padding: 20px;
                }
                .item__desc {
                    display: block;
                    font-size: 18px;
                    text-align: center;
                    line-height: 1.2;
                }
                &:hover {
                    text-decoration: none;
                    img {
                        border: 1px solid #A865C4;
                        border-radius: 8px;
                    }
                    .item__desc {
                        color: #A865C4;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

@media (max-width: 1440px) {
    .produtos {
        &__title {
            font-size: 24px;
        }
        &__list {
            grid-column-gap: 20px;
        }
    }
    .box-title-purple {
        font-size: 24px;
        .btn-voltar{
            font-size: 14px;
        }
    } 
}
@media(max-width: 991px){
    .produtos{
        .box-title{
         padding: 10px 15px;
       }
       &__title{
        font-size: 20px;
       }
      &__list{
        .item__link .item__desc{
            font-size: 16px;
        }
      } 
    }  
  }

@media (max-width: 768px) {
    .produtos {
        &__list {
            grid-column-gap: 10px;
            .item {
                &__link {
                    img {
                        padding: 5px;
                    }
                    .item__desc {
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .box-title-purple {
        font-size: 20px;
        padding:  10px 15px;
    } 
}
@media (max-width: 540px) {
    .produtos {
        &__title{
            font-size: 18px;
        }
        &__list {
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 10px;
            .item {
                &__link {
                    img {
                        padding: 20px;
                    }
                }
            }
        }
    }
    .box-title-purple {
        font-size: 18px;
    } 
}