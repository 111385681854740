@charset "UTF-8";

@font-face {
  font-family: "Politecsaude";
  src:url("../fonts/politecsaude.eot");
  src:url("../fonts/politecsaude.eot?#iefix") format("embedded-opentype"),
    url("../fonts/politecsaude.woff") format("woff"),
    url("../fonts/politecsaude.ttf") format("truetype"),
    url("../fonts/politecsaude.svg#politecsaude") format("svg");
  font-weight: normal;
  font-style: normal;

}

// Catchall baseclass
.icon {
  position: relative;
  // top: -1px;
  display: inline-block;
  vertical-align: middle;
  font-family: $font-icons;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: $font-icons;
}

.icon-chevron-down:before {
  content: "\e000";
}
.icon-chevron-left:before {
  content: "\e001";
}
.icon-chevron-right:before {
  content: "\e002";
}
.icon-chevron-up:before {
  content: "\e003";
}
.icon-search:before {
  content: "\e004";
}
.icon-padlock:before {
  content: "\e005";
}
.icon-pdf:before {
  content: "\e006";
}
.icon-petal:before {
  content: "\e007";
}
.icon-instagram:before {
  content: "\e008";
}
.icon-twitter:before {
  content: "\e009";
}
.icon-whatsapp:before {
  content: "\e00a";
}
.icon-youtube:before {
  content: "\e00b";
}
.icon-linkedin:before {
  content: "\e00c";
}
.icon-facebook:before {
  content: "\e00d";
}
.icon-vm2:before {
  content: "\e00e";
}