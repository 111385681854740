//Colors manual
$orange:      #F58220;
$yellow:      #FFA304;
$purple:      #A865C4;
$green:       #5D9B16;
$teal:        #2DADC0;
$blue:        #46AEEC;
$gray:        #D5D5D5;
$dark-gray:   #605D5C;
$light-gray:  #EFEFEF;

//Informational Colors
$black: #000;
$white: #fff;

//text-colors
$font-color: #707070;

//gradients
$gradient-yellow: linear-gradient(to bottom, $yellow, #ca8001);
$gradient-purple: linear-gradient(to bottom, $purple, #361A3C);
$gradient-green:  linear-gradient(to bottom, $green, #61AA0D);
$gradient-gray:   linear-gradient(to bottom, $gray, #D0D0D0);

//Fonts
// $font-title:    "Calibri", Verdana;
$font-default:  "Calibri", Verdana;
$font-icons:    "Politecsaude";

//Font Sizes
// $title-xxl:     82px;
// $title-xl:      64px;
// $title-lg:      56px;
// $title-md:      48px;
// $title-sm:      36px;
// $title-xs:      28px;
// $title-xxs:     24px;
// $subtitle:      20px;
// $paragraph-lg:  18px;
// $paragraph:     16px;
// $paragraph-xs:  14px;
// $caption:       12px;