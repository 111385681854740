body {
  color: $font-color;
  font-family: $font-default;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.opened-menu {
    overflow-y: hidden;
  }

  main{
    flex-grow: 1;
  }
}
a {
  color: inherit;

  &:hover {
    color: inherit;
  }
  &.orange {
    &:hover {
      color: $yellow;
    }
  }
}
.orange {
  color: $orange;

  &:hover {
    color: $orange;
  }
}



.input-form{
  border-radius: 30px;
  border: solid 1px $gray;
  padding: 10px;
  font-size: 16px;
  width: 100%;
 

  &::placeholder{
    font-size: 14px;
    color: $gray;
  }
  &:focus-visible{
    outline: none;
  }
}
.pagination{
  justify-content: flex-end;
  align-items: center;
  .page-item{
    .page-link{
      background: $light-gray;
      color: $dark-gray;
      border: none;
      margin-left: 3px;
      border-radius: 5px;
      transition: .3s;
      &.arrow{
        font-size: 24px;
        line-height: .9;
        background: transparent;
      }
    }
    &:not(.disable){
      &.active, &:hover{
        .page-link{
          box-shadow: none;
          background: $orange;
          color: $white;
        }
      }
    }
    &.disable{
      pointer-events: none;
      opacity: .5;
    }
  }
  
}