.search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &__form {
        
            display: flex;
            align-items: flex-start;
            position: relative;
            gap: 10px;
            
            input {
                width: 200px;
                border-radius: 99999px;
                background: $purple;
                &::placeholder{
                    color: $white;
                }
                &:focus{
                    color: $white;
                    background: $purple;

                }
            }
            .icon{
                position: absolute;
                right: 0;
                top: 10px;
                background: none;
                border: none;
                color: $white;
            }
            .btn__loupe {
                background-image: url(../img/loupe.png);
                background-repeat: no-repeat;
                background-position: center;
                padding: 8px 20px;
                border: none;
                border-radius: 99999px;
                width: 62px;
                &::after {
                    content: "";
                    position: absolute;
                    width: 30px;
                    height: 20px;
                    display: block;
                    background: url('../img/loupe.png');
                }
            }
        
    }
    &__download {
        max-width: 300px;
        border: solid 1px $gray;
        border-radius: 5px;
        a {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px 10px;
            p {
                margin: 0;
                font-size: 15px;
            }
        }
    }
}