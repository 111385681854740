.menu__interno {
    margin-bottom: 10px;
    .navbar-brand {
        font-size: 32px;
    }
    & > div {
        display: flex;
        justify-content: end;
        align-items: center;
        img {
            margin-right: 16px;
        }
    }
    .menu__empresa {
        .nav {
            display: flex;
            align-items: center;
            gap: 8px;
            list-style: none;
            
                &-link {
                    font-size: 18px;
                    &:hover {
                        color: $orange;
                        text-decoration: underline;
                    }
                }
                .active {
                    color: $orange;
                    text-decoration: underline;
                }
            
        }
    }
}

.breadcrumb{
    background: transparent;
    padding: 0;
    margin: 0 0 10px;
    color: #9C9C9C
}

.breadcrumb-item+.breadcrumb-item::before{
    content: '›';
    color: $orange;
    font-size: 24px;
    line-height: .9;
}

.detail-purple{
    .breadcrumb-item+.breadcrumb-item::before{
        color: $purple;
    }
}
.detail-blue{
    .breadcrumb-item+.breadcrumb-item::before{
        color: $teal;
    }
}
.detail-yellow{
    .breadcrumb-item+.breadcrumb-item::before{
        color: #ffcc00;
    }
}

@media(max-width: 1440px){
    .menu__interno {
        .navbar-brand {
            font-size: 28px;
        }
        .menu__empresa {
            .nav {
                &-link {
                    font-size: 16px;
                }
            }
        }
    }
}
@media(max-width: 1024px){
    .menu__interno {
        .navbar-brand {
            font-size: 24px;
        }
        .menu__empresa {
            .nav {
                &-link {
                    font-size: 17px;
                }
            }
        }
    }
}
@media(max-width: 768px){
    .menu__interno {
        .navbar-brand {
            font-size: 20px;
        }
        .menu__empresa {
            .nav {
                &-link {
                    font-size: 16px;
                }
            }
        }
    }
}
@media(max-width: 540px){
    .menu__interno {
        .navbar-brand {
            font-size: 18px;
        }
    }
}